<!-- 我的 -->
<template>
    <div class="my">
        <ul>
            <li>
                <div class="one">使用统计</div>
                <div class="two two_one">
                    <el-row>
                        <el-col :span="8"><div class="grid-content bg-purple">
                            <!-- 收藏试题 <span>1</span> 道 -->
                        </div></el-col>
                        <el-col :span="8"><div class="grid-content bg-purple">
                            <!-- 下载试卷 <span>0</span> 次 -->
                        </div></el-col>
                        <el-col :span="8"><div class="grid-content bg-purple">
                            存档试卷 <span>{{paperlist.length}}</span> 次
                        </div></el-col>
                    </el-row>
                </div>
            </li>
            <li>
                <div class="one">最近存档 <span class="fr" @click="btn()">更多</span></div>
                <div class="two two_two_one">
                    <el-col :span="10"><div class="grid-content bg-purple">
                        <div>
                            <p>试卷名称</p>
                        </div>
                    </div>
                    </el-col>
                    <el-col :span="6"><div class="grid-content bg-purple">
                        <div>
                            <p>存档时间</p>
                        </div>
                    </div>
                    </el-col>
                    <el-col :span="4"><div class="grid-content bg-purple">
                        <div>
                            <p>试题数量</p>
                        </div>
                    </div>
                    </el-col>
                    <el-col :span="4"><div class="grid-content bg-purple">
                        <div>
                            <p>功能</p>
                        </div>
                    </div>
                    </el-col>
                </div>

                <div class="two_two_two" v-for="item in paperlist.slice(0,num)" :key="item.id">
                    <el-col :span="10">
                        <div class="grid-content bg-purple">
                            <div>
                                <p>{{item.title}}</p>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="grid-content bg-purple">
                            <div>
                                <p>{{item.examtime}}</p>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="grid-content bg-purple"> 
                            <div>
                                <p>{{item.ques_ids.split(',').length}}</p>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="grid-content bg-purple">
                            <div>
                                <span @click="listdetails(item)">查看详情</span>
                                <!-- <span v-show="Personal.user_type == 3" @click="listzhuanfa(item)">转发</span> -->
                                <!-- <el-dropdown @command="handleCommand">
                                    <span class="el-dropdown-link">
                                        转发<i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item :command="[item,1]">预习</el-dropdown-item>
                                        <el-dropdown-item :command="[item,2]">复习</el-dropdown-item>
                                        <el-dropdown-item :command="[item,3]">学习空间</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown> -->
                            </div>
                        </div>
                    </el-col>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import axios from "axios";
import URL from "@/api/index";
export default {
    data() {
        return {
            visible: false,
            checkList:[],
            paperlist:[],
            num:5,
            tianjia:false,
            shiti:[],

            nums: 0,
            nums1: 0,

            length:0,
            token:'',
            typelist:'',
            Spaceid:'',
            userType:[
                {id:'2',identity:"学员"},
                {id:'3',identity:"教师"},
                {id:'4',identity:"校长"},
                {id:'5',identity:"学生"},
                {id:'6',identity:"教育局"}
            ],
            // 个人信息列表
            Personal:'',

            // 数据上报字段
            client_ip:"",
        }
    },
    watch: {
        nums(val) {
            if(this.shiti.length == val - this.nums1){
                var obj = {};
                this.shiti.forEach(function(data,index){
                    obj[data.question_type] = obj[data.question_type] || []
                    obj[data.question_type].push(data)
                })
                localStorage.setItem("shiti", JSON.stringify(obj))
                localStorage.setItem("shitilength", JSON.stringify(this.shiti.length))
                // this.$router.push('examPaper')
            }
        },
    },
    mounted(){
        this.token = JSON.parse(localStorage.getItem("loginInfo")).token
        // console.log( window.atob(this.token),"获取到的token")
        this.list()
        this.getQuestionType()
        this.login()
        this.userspace()
    },
    methods:{
        //获取个人信息
        login(){
            var data = {
                token: this.token
            }
            this.$post('https://api.rujiaowang.net/index.php/api/user/getuser',data)
            .then( item => {
                // console.log(item);
                this.Personal = item.result
                this.userType.forEach(function(res){
                    // console.log(item.result.user_type);
                    if(res.id == item.result.user_type){
                        // console.log(res.identity);
                    }
                })
            })
        },
        // 查询制定用户的空间   
        userspace(){
            var userdata = {
                token: this.token
            }
            this.$post('https://api.rujiaowang.net/netlearn/space/getUserspace',userdata).then(item=>{
                // console.log(item);
            })
        },
        //历史试卷列表
        list(){
            var data = {
                token: this.token
            };
            this.$post(URL.GetUserExam, data).then((res) => {
                this.paperlist = res.result.rows.reverse()
            })
        },
        //试题类型
        getQuestionType() {
            var data = {
                gid: '',
                sid:  '',
                scid: '',
            };
            this.$post(URL.getQuestionType, data).then((res) => {
                this.typelist = res.result;
            });
        },
        //打开试卷详情
        listdetails(e){
            var that = this;
            var data = {
                exam_id:e.exam_id,
                token: this.token
            };
            this.$post(URL.GetByIdExam, data).then((res) => {
                // that.detailswork(e)
                that.nums = 0
                that.nums1 = 0
                that.shiti = []

                res.result.content.forEach(function(data){
                    
                    data.questions.forEach(function(item){
                        
                        var html = item.question_text

                        var urltext2 = 'http://rjwtiku.oss-cn-beijing.aliyuncs.com'

                        if (item.store_type == 2) {  
                            // console.log('旧题')
                            var urltext = html.match(/(\S*)SYS/)[1]
                            // html 地址解析
                            that.$get(item.answer).then((data2) => {
                                var reg = new RegExp('src="','g')
                                var realurl2 = data2.replace(reg, 'src="' + urltext)
                                item.answer = realurl2
                                that.nums++
                            })
                            that.$get(item.question_text).then((data3) => {
                                var reg = new RegExp('src="','g')
                                var realurl2 = data3.replace(reg, 'src="' + urltext)
                                item.question_text = realurl2
                                that.nums++
                            })
                            
                            that.nums1++
                        } else {
                            // console.log('新题')

                            // 图片路径重新处理
                            var reg = new RegExp('src="','g')
                            // 题目
                            var question_text = item.question_text.replace(reg, 'src="' + urltext2)
                            // 选项
                            var options_json = item.options_json.replace(reg, 'src="' + urltext2)
                            // 答案
                            var answer = item.answer.replace(reg, 'src="' + urltext2)
                            // 解析
                            var analysis = item.analysis.replace(reg, 'src="' + urltext2) 
                            
                            item.question_text = question_text
                            item.options_json =  options_json
                            
                            item.analysis = analysis
                            if(analysis){

                            } else {
                                item.analysis = '略'
                            }
                            item.answer = "<div>答案:</div>"+ answer + "<div>解析:</div>" + item.analysis

                            that.nums++
                        }
                        that.shiti.push(item)
                        that.typelist.forEach(function(res){
                            if(item.question_type == res.type_id){
                                item.question_type = res.type_name
                            }
                        })
                    })
                })

                this.$router.push("/testPaper/examPaper");
            })  
        },
        handleCommand(a){
            this.listzhuanfa(a)
        },
        //转发
        // listzhuanfa(val){
        //     var data = '?token='+this.token+'&forwapp='+'&forward='+
        //                '&shareimg=http://14.116.152.239:9000/resourse/format/word.png'+'&sharename='+val[0].title+
        //                '&shareurl='+val[0].file_url
        //     var kjdata = '?token'+this.token+'&title='+val[0].title+
        //                  '&href=http://14.116.152.239:9000/resourse/format/word.png'+
        //                  '&url='+val[0].file_url+
        //                  '&forward=&forwapp='
        //     if(val[1] ==3){
        //         //学习空间
        //         window.open('http://www.rujiaowang.net/app/shareIndex'+kjdata,'_blank',)
        //     }else {
        //         if(val[1] == 1){
        //             // 预习
        //             window.open('http://www.rujiaowang.net/app/yuxi/teacher/index.html#/sharesend'+data,'_blank',)
        //         } else {
        //             //复习
        //             window.open('http://www.rujiaowang.net/app/fuxi/teacher/index.html#/sharesend'+data,'_blank',)  
        //         }
        //     }
        // },
        btn(){
            if(this.num > 5){
                this.num = 5
            } else{
                this.num = this.paperlist.length
            }
        },

        // 数据上报
        // 查看详情
        detailswork(e){
            var iteminfo = JSON.parse(localStorage.getItem("loginInfo"));
            axios.get("http://access.rujiaowang.net/GetLogServer").then((res) => {
                this.client_ip = res.data.result.client_ip;
                var differenctime = res.data.result.net_ts - Date.parse(new Date()) / 1000;
                
                var saydata = {
                product_name: "教师工作台",
                log_version: "v2.0.1",
                action: "testgroup_look",
                user_id: String(iteminfo.user_id),
                user_role: String(iteminfo.user_type),
                user_name: String(iteminfo.user_nicename),
                ts: String(Number(Date.parse(new Date())) / 1000 + differenctime),
                trace_id: JSON.parse(localStorage.getItem("data_traceid")),
                dev_id: "",
                dev_name: "",
                client_ip: this.client_ip,
                os: "",
                cpu_name: "",
                cpu_use: "",
                app_version: "v2.0.0",
                mem_size: "",
                mem_use: "",
                net: "",
                electric: "",
                extend: "",
                remark: `${iteminfo.user_nicename}` + "使用了组卷的查看详情功能",
                data: {
                    school_name: iteminfo.teacher_info.school_name
                    ? `${iteminfo.teacher_info.school_name}`
                    : "",
                    school_id: iteminfo.teacher_info.school_id
                    ? `${iteminfo.teacher_info.school_id}`
                    : "",
                    trace_id: JSON.parse(localStorage.getItem("prepare_traceid")),
                    title_one:"我的",
                    test_name:e.title,
                    archive_time:e.examtime,
                    topicnum:String(e.ques_ids.split(',').length)
                },
                };
                axios.post("http://47.114.114.56:9002/RecordRjw", saydata).then(res =>{})
            })
        }
    }
};
</script>

<style lang="less" scoped>
.my {
    margin: 24px 0;
    ul {
        li {
            padding: 15px 25px;
            margin-bottom: 20px;
            background: #fff;
            box-shadow: 0px 0px 6px 0px
		    rgba(200, 200, 200, 0.47);
            min-height: 187px;
            font-size: 16px;
            color: #2f2f2f;
            .one {
                margin-bottom: 15px;
                padding-left: 5px;
                border-left: 7px solid #3a8afd;
                span {
                    cursor: pointer;
                    display: inline-block;
                    color: #2c84ff;
                    font-size: 14px;
                    line-height: 24px;
                }
            }
            .two {
                border-top: 1px solid #eeeeee;
                overflow: hidden;
            }
            .two_one {
                line-height: 136px;
                text-align: center;
                .grid-content {
                    font-size: 14px;
                }
                span {
                    display: inline-block;
                    font-size: 30px;
                    letter-spacing: 0px;
                    color: #ff5151;
                }
            }
            .two_two_one,.two_three_one {
                div {
                    p {
                        color: #2f2f2f;
                        line-height: 54px;
                    }
                }
            }
            .two_two_two,.two_three_two{
                overflow: hidden;
                cursor:pointer;
                div {
                    p {
                        color: #929292;
                        font-size: 14px;
                        line-height: 34px;
                    }
                    span{
                        display: inline-block;
                        padding: 4px 10px;
                        border-radius: 5px;
                        background: #3a8afd;
                        color: #fff;
                        font-size: 12px;
                        margin-right: 5px;
                        margin-top: 5px;
                    }
                }
            }
            .zanwu{
                text-align: center;
                font-size: 14px;
                line-height: 30px;
            }
        }
    }
}
</style>
