<template>
  <div>
    <top></top>
    <navMenu></navMenu>
    <div class="inner">
      <navbar></navbar>
      <employ></employ>
    </div>
  </div>
</template>

<script>
import top from "@/components/navtop.vue";
import navMenu from "@/components/menuNav.vue";
import navbar from "../../components/testPaper/navbar";
import employ from "../../components/testPaper/employ";
export default {
  components: {
    top,
    navMenu,
    navbar,
    employ,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
</style>
